import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Team from './Team.jsx';
import { sendDraw1Txns, sendCollectTxns, sendExecTxns } from '../algo';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCellReal from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRowReal from '@mui/material/TableRow';
import { ALGO } from '../constants.js';
import Tooltip from '../components/Tooltip.jsx';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { HFlex } from './Layout.jsx';

const drawOddsTooltip = 'The target draw odds for this CupStake.'

const collectedTooltip = 'Number of team CupStakes collected from the contract.'

const winValueTooltip = 'The current share of the rewards pool that this team will be redeemable for, if this team wins the World Cup.';

const runnerUpValueTooltip = 'The current share of the rewards pool that this team will be redeemable for, if this team comes second in the World Cup.';

function TableRow({ children }) {
  return <TableRowReal sx={{ display: { xs: 'flex', md: 'table-row'}, mt: { xs: 1, md: 0 }, borderTop: { xs: '1px var(--primary) solid' }, flexDirection: { xs: 'column', md: '' } }}>
    {children}
  </TableRowReal>
}

function TableCell({ children, header }) {
  return <TableCellReal sx={{display: { xs: 'flex', md: 'table-cell' }}}>
    { header ? <>
      <HFlex sx={{justifyContent: 'flex-start', display: { xs: 'flex', md: 'none' }, flexGrow: 1}}>{header}</HFlex>
    </> : null }
    <HFlex>
    {children}
    </HFlex>
  </TableCellReal>
}

function AllNFTs({ assets }) {
  const data = assets.entries()
    .sort(([_, {rank: r1, odds: o1, ...p}], [__, {rank: r2, odds: o2}]) => {
      if (o1 === o2) {
        return r1 < r2 ? -1 : 1;
      }
      return o1 < o2 ? -1 : 1;
    });

  return <Table>
    <TableHead sx={{ display: { xs: 'none', md: 'table-header-group' } }}>
      <TableRow>
        <TableCell>Team</TableCell>
        <TableCell>Rank</TableCell>
        <TableCell>Draw Odds<Tooltip className="float-bottom">{drawOddsTooltip}</Tooltip></TableCell>
        <TableCell>Collected<Tooltip className="float-bottom">{collectedTooltip}</Tooltip></TableCell>
        <TableCell>Win Value<Tooltip size="inherit" className="float-bottom">{winValueTooltip}</Tooltip></TableCell>
        <TableCell>Runner-up Value<Tooltip className="float-bottom">{runnerUpValueTooltip}</Tooltip></TableCell>
      </TableRow>

    </TableHead>
    <TableBody>
      { data.map(([id, {flag, name, available, odds, issued, wv, ruv, rank, rankLabel }]) =>
      <TableRow key={`team-${id}`}>
        <TableCell>{flag} {name}</TableCell>
        <TableCell header={<>Rank</>}>{rank} / {rankLabel}</TableCell>
        <TableCell header={<>Draw Odds<Tooltip>{drawOddsTooltip}</Tooltip></>}>{available ? odds.toFixed(2)+"%" : 'Disqualified'}</TableCell>
        <TableCell header={<>Collected<Tooltip>{collectedTooltip}</Tooltip></>}>{issued}</TableCell>
        <TableCell header={<>Win Value<Tooltip>{winValueTooltip}</Tooltip></>}>{available && wv ? `${ALGO} ${wv.toFixed(0)}` : 'N/A'}</TableCell>
        <TableCell header={<>Runner-up Value<Tooltip>{runnerUpValueTooltip}</Tooltip></>}>{available && ruv ? `${ALGO} ${ruv.toFixed(0)}` : 'N/A'}</TableCell>
      </TableRow>
    ) }
    </TableBody>
    </Table>;
}

export default observer(AllNFTs);
