import algosdk from 'algosdk'
import savedContract from './contract.json';

const clientMethods = [
  'draw',
  'free_draw',
  'burn_draw',
  'burn_draw2',
  'burn_draw3',
  'draw3',
  'exec_draw',
  'collect',
];

export const contractMethodNames = {}
export const contractMethodReverse = {}

const contract = new algosdk.ABIContract(savedContract);
for(const method of contract.methods) {
  const { name } = method;
  if (clientMethods.includes(name)) {
    contractMethodNames[name] = method.getSelector();
    contractMethodReverse[Buffer.from(method.getSelector()).toString("base64")] = name;
  }
}


export const ALGO = 'Ⱥ';
