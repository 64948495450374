import { useEffet, createRef } from 'react';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import './App.css';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import theme from './theme.js';
import { ThemeProvider } from '@mui/material/styles';
import Layout from './components/Layout.jsx';
import Fourohfour from './routes/Fourohfour';
import Home from './routes/Home';
import Play from './routes/Play';
import Teams from './routes/Teams';
import Draw from './routes/Draw.jsx';
import { NotificationBridge } from './notifications';
import { account, globalAppState, localAppState, localAssets, assets } from './state/';
import FlagZoom from './routes/FlagZoom';
import Countdown from './routes/Countdown';
import Stopwatch from './routes/Stopwatch.js';
import FAQ from './routes/FAQ.js';
import HistoryBridge from './history-bridge.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


function App() {

  const notistackRef = createRef();

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={theme}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          ref={notistackRef}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          )}
          >
          <NotificationBridge />
          <CssBaseline />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout localAppState={localAppState} />}>
                <Route path="/" exact={true} element={<Home assets={assets} globalAppState={globalAppState} />} />
                <Route path="play" element={<Play account={account} localAppState={localAppState} globalAppState={globalAppState} localAssets={localAssets} />} />
                <Route path="teams" element={<Teams assets={assets} />} />
                <Route path="faq" element={<FAQ />} />
                <Route path="*" element={<Fourohfour />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
    </ThemeProvider>

  );
}

export default App;
