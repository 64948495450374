import { useState, useCallback } from 'react';
import Button from '@mui/material/Button';

export default function({ value, ...props }) {
  const [label, setLabel] = useState('COPY');
  const [color, setColor] = useState('info');
  const [disabled, setDisabled] = useState(false);

  const reset = () => { setColor('info'); setLabel('COPY'); setDisabled(false); }

  const doCopy = useCallback(() => {
    try {
      navigator.clipboard.writeText(value);
      setLabel('✔ COPIED');
      setColor('success');
      setDisabled(true);
    } catch(e) {
      setLabel('ERROR');
      setColor('error');
    }
    setTimeout(reset, 2000);
  }, [value]);

  return <Button {...props} disabled={disabled} onClick={doCopy}>{label}</Button>
}
