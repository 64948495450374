import { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { ALGO } from '../constants.js';
import Tooltip from './Tooltip.jsx';
import './Team.css';
import Link from '@mui/material/Link';
import Desaturate from './Desaturate.jsx';
import Images from '../images/cupstakes-images.js';
import Box from '@mui/material/Box';

function WVTooltip({children, name, val, cond="wins the 2022 World Cup"}) {
  const inner = children ?? [`${name} CupStake Win Value`];
  return <Tooltip extra={<>If {name} {cond}, its CupStake will be redeemable for {ALGO}{val.toFixed(2)}.<br /><br />As the cup progresses, the CupStakes ticket draw price will increase.<br/>This mechanic ensures that early CupStakes drawing is incentivised and the CupStakes Win Values increase.<br /><br/>Read more about this <Link heef="/docs/ticket">here</Link></>}>
    {inner}
  </Tooltip>;
}

function RUVTooltip(props) {
  return <WVTooltip {...props} cond="is the 2022 World Cup runner-up" >{props.name} CupStake Runner-up Value</WVTooltip>;
}

function CupStake(props) {
  const { id, flag, code, name, available, odds, amount, issued, showIssued, wv, ruv, rotating, stopOnHover, burn, isNew, short, backflip, sx } = props;

  const [anchorEl, setAnchorEl] = useState();

  const open = Boolean(anchorEl);
  const popid = open ? code : undefined;

  const handleShow = (event) => {
    // console.log("set");
    setAnchorEl(event.currentTarget.parentNode);
  };

  const handleHide = () => {
    // console.log("unset");
    setAnchorEl(null);
  };

  const cupstakeClassName = `cupstake ${short?'short':''} ${burn?'burn':''}`;
  const innerClassName=`cupstake-inner ${backflip?`backflip backflip${backflip}`:''} ${burn ? 'scale08' : ''} ${stopOnHover?'stop-on-hover':''} ${rotating?'rotating':''}`;

  return <Box className={cupstakeClassName} sx={sx}>
      <div className={innerClassName}>
        <div className="front">
          <img src={Images[code]} onTouchStart={handleShow} onTouchEnd={handleHide} onMouseEnter={handleShow} onMouseLeave={handleHide} />
          { amount ? <div className="amount">{amount}x</div> : null }
          { isNew ? <div className="amount new">NEW</div> : null }
        </div>
        <div className="back">
          <img src={Images.back} />
        </div>
      </div>
    </Box>
}

export default CupStake
