import Account from './account';
import LocalAppState from './local-app-state';
import GlobalAppState from './global-app-state';
import makeCollection from './collection.js';
import FSM from './fsm.js';
import DoNotShowAgain from './do-not-show-again.js';
import Algorand from './algorand.js';

export const account = new Account();
export const localAppState = new LocalAppState();
export const globalAppState = new GlobalAppState();

const LocalAssets = makeCollection('LocalAssets', ['opted_in', 'amount']);
export const localAssets = new LocalAssets();

const Assets = makeCollection('Assets', ['id', 'code', 'name', 'available', 'odds', 'issued', 'wv', 'ruv', 'flag', 'rank',  'rankLabel']);
export const assets = new Assets();

export const fsm = new FSM(globalAppState, localAppState, localAssets, assets, account);

export const doNotShowAgain = new DoNotShowAgain();
export const algorand = new Algorand();
