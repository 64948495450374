import { get, set, has, keys, entries, values, observable, remove, runInAction } from "mobx"

function makeCollection(name, assetValueKeys) {
  return class Collection {
    constructor() {
      this.assetData = observable.object({});
    }
    get(id) {
      id=String(id)
      return get(this.assetData, id);
    }
    keys() {
      return keys(this.assetData);
    }
    values() {
      return values(this.assetData);
    }
    entries() {
      return entries(this.assetData);
    }
    sort(key) {
      return entries(this.assetData).sort((a, b) => {
        return a[1][key] < b[1][key] ? -1 : 1
      });
    }
    sortById() {
      return entries(this.assetData).sort((a, b) => a[0] < b[0] ? -1 : 1);
    }
    some(fn) {
      return values(this.assetData).some(fn);
    }
    replaceState(id, newValues) {
      const newData = {};
      for(const [key, value] of Object.entries(newValues)) {
        if (!assetValueKeys.includes(key)) {
          throw new Error(`${name} setData tried to set unknown key ${key}`);
        }
        newData[key] = value;
      }
      runInAction(() => set(this.assetData, String(id), newData));
    }
    resetState(id) {
      remove(this.assetData, id);
    }
  }
}

export default makeCollection;
