import { useState, useEffect } from 'react';
import { useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { observer } from 'mobx-react-lite';
import Header from './Header';
import { account, algorand } from '../state/index';
import { localAppState, assets } from '../state/index.js';
import Preload from '../images/cupstakes-preload.js';
import useScroll from '../hooks/scroll.js';
import NetWatch from './algo-net-watch.js';
import throttle from 'lodash/throttle';
import FontFaceObserver from 'fontfaceobserver';
import MuiLink from '@mui/material/Link';
import { HashLink as RouterLink } from 'react-router-hash-link';

export function Link({ href, children, ...props }) {
  const component = href.startsWith('http') || href.startsWith('mailto') ? { target: "_blank", href: href } : { component: RouterLink, to: href };
 return <MuiLink {...component} {...props}>{children}</MuiLink>
}


export function Left({ children, sx, ...props }) {
  return <VFlex sx={{alignItems: 'flex-start', ...sx}} {...props}>{children}</VFlex>;
}

function setStyleProperty(key, value) {
  document.documentElement.style.setProperty(key, value);
}

const setStylePropertyThrottled = throttle(setStyleProperty, 50, { leading: true });

function Scroll() {
  const scrollPosition = useScroll();

  useEffect(() => {
    const deg = scrollPosition / 60 % 360;
    setStylePropertyThrottled('--rotate', `${deg}deg`);
  }, [scrollPosition]);

  return <></>;
}

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    const path = location.pathname + location.search + location.hash;
    console.log('Tracking', path);
    window.goatcounter?.count({ path });
  }, [location]);
}

function useLoading() {
  //  TODO font loader for load
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      setTimeout(() => setLoaded(true), 5_000);
      const observers = [
        new FontFaceObserver('Maldini'),
        new FontFaceObserver('MaldiniStyle'),
        new FontFaceObserver('MaldiniBold'),
        new FontFaceObserver('Roboto'),
      ];
      await Promise.all(observers.map(o => o.load()));
      setLoaded(true);
    })()
  }, []);
  
  return [loaded];
}

export default function () {
  usePageViews();
  const [loaded] = useLoading();

  return loaded ? (<>
    <Header account={account} />
    <Container className="layout"
      disableGutters
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: { xl: 0.7, lg: 0.8, md: 1.0, sm: 1, xs: 1 },
      }}>
      <Outlet />
    </Container>
    <Scroll />
    <Preload />
    <NetWatch algorand={algorand} />
    <Logo />
    </>
  ) : <Container className="layout"
    disableGutters
    sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: { xl: 0.7, lg: 0.8, md: 1.0, sm: 1, xs: 1 },
    }}>
    <Logo />
    <div class="loader-5 center"><span></span></div>
  </Container>;
};

function Logo() {
  return <>
    <div className="center-fade"></div>
    <div className="background-logo"></div>
  </>;
}

export function HFlex({children, hide, ...props}) {
  const { sx, ...restProps } = props;
  return <Box {...restProps} sx={{display: hide ? 'none' : 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', ...sx}}>{children}</Box>
}

export function VFlex({children, ...props}) {
  const { sx, ...restProps } = props;
  return <HFlex {...restProps} sx={{flexDirection: 'column', ...sx}}>{children}</HFlex>
}

export function VSeparator({ vh, sx, ...props}) {
  const finalSX = { flexGrow: 1, minHeight: '3vh', ...sx };
  if (vh) {
    finalSX.minHeight = typeof vh === "object" ? vh : `${vh}vh`;
  }
  return <VFlex {...props} sx={finalSX}></VFlex>
}

export function Opaque({ sx, h1, h2, op = 0.9, ph = {xs: 2, md: 5}, children, ...props }) {
  let finalSX = { pt: 2, pb: 3, background: `rgba(255,255,255,${op})` };
  if (h1)
    finalSX.pb = 0;
  if (h2)
    finalSX.pb = 1;

  if (ph) {
    finalSX.pl = ph;
    finalSX.pr = ph;
  }
  if (sx) {
    finalSX = { ...finalSX, ...sx };
  }
  return <VFlex className="opaque" {...props} sx={finalSX}>
    {children}
  </VFlex>
}
