import { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Team from './Team.jsx';
import { optinApp, sendFreeDrawTxns, sendDraw1Txns, sendDraw3Txns, sendCollectTxns, sendExecTxns, sendBurnTxns } from '../algo';
import { ALGO } from '../constants.js';
import { fsm, algorand } from '../state/index.js';
import Wallet from './Wallets.jsx';
import DownloadIcon from '@mui/icons-material/Download';
import HourglassIcon from './hourglass-icon.jsx';
import DiceIcon from '@mui/icons-material/Casino';
import Typography from '@mui/material/Typography';
import { Opaque, VSeparator, VFlex, HFlex, Link } from './Layout.jsx';
import BurnExplainer, { first as BurnExplainerSummary } from './explainers/BurnExplainer.jsx';
import CollectExplainer, { first as CollectExplainerSummary } from './explainers/CollectExplainer.jsx';
import BurnIcon from '@mui/icons-material/LocalFireDepartment';
import Tooltip from './Tooltip.jsx';
import TxnButton from './txn-button.js';
import Grow from '@mui/material/Grow';
import './Play.css';
import DrawWatcher from './draw-watcher.js';

// send setDisabled to draw/draw3/...?
// or use FSM state?

function FreeDrawBtn(props) {
  return <TxnButton size="big" {...props} icon={<DiceIcon/>} onClick={sendFreeDrawTxns}>FREE DRAW 1x ({ALGO} 0.00)</TxnButton>;
}

function DrawBtn({ ticket, times=1, callback=sendDraw1Txns, ...props }) {
  const tipShort = <>Pay for {times}x CupStakes draw{times > 1 ? 's':''} ({ALGO} {ticket}).</>;
  const tip = <Tooltip sx={{mr: '-28px'}} extra={<>{tipShort}<br/><br/>Your Draw will be automatically executed when new verifiable randomness is available, usually around 10-30s.<br /><br />You can see how team odds are weighed on the <Link href="/teams">Teams page</Link>.</>}>{tipShort}</Tooltip>;

  return <TxnButton tooltip={tip} size="big" {...props} icon={<DiceIcon/>} onClick={callback}>DRAW {times}x ({ALGO} {ticket})</TxnButton>;
};

function Draw3Btn({ticket, ticketAlgo, ...props}) {
  return <DrawBtn ticket={(3*ticket).toFixed(2)} {...props} times={3} callback={sendDraw3Txns} />
}

const burnIcon = <BurnIcon />;
function BurnBtn({ticket, toBurn, setToBurn}) {
  const amt = toBurn.length;
  // const price = (ticket * amt).toFixed(2);
  const price = (ticket * amt).toFixed(2);
  const callback = async () => {
    await sendBurnTxns(...toBurn);
    setToBurn([]);
  }
  return <HFlex>
    <TxnButton sx={{mr: '5px', ml: '32px' }} icon={burnIcon} onClick={callback}>BURN & RE-DRAW {amt}x ({ALGO} {price})</TxnButton>
    <Tooltip extra={<BurnExplainer />}>{BurnExplainerSummary}</Tooltip>
  </HFlex>;
}

function CollectBtn({ slots, disabled, setModal, doNotShowAgain }) {
  const callback = () => doNotShowAgain.renderAwait({
      setModal,
      uniqueKey: 'collect',
      title: 'Collecting CupStakes',
      explainer: <CollectExplainer />,
      callback: sendCollectTxns,
      setModal,
    });
  const tooltip = <Tooltip sx={{mr: '-28px'}} extra={<CollectExplainer />}>{CollectExplainerSummary}</Tooltip>;
  return <TxnButton disabled={disabled} icon={<DownloadIcon />} tooltip={tooltip} onClick={callback}>COLLECT{slots.length>1?" ALL":""}</TxnButton>;
}

function Play({ ownTeams, localAppState, assets, localAssets, globalAppState, account, doNotShowAgain }) {
  const { connect, optin, draw1, draw3, freedraw, execute, collect } = fsm.getAvailableActionsObj();
  const [modal, setModal] = useState(null);
  const [toBurn, setToBurn] = useState([]);

  const toggleBurn = useCallback((i) => {
    const isBurn = toBurn.includes(i);
    if (isBurn) {
      setToBurn(toBurn.filter(burn => burn !== i));
    } else {
      setToBurn([...toBurn, i]);
    }
  }, [toBurn]);

  const toggleBurnWithExplainer = useCallback((i) => {
    doNotShowAgain.renderAwait({
      setModal,
      uniqueKey: 'burn',
      title: 'Burn & Re-draw explained',
      explainer: <BurnExplainer />,
      callback: () => toggleBurn(i),
      setModal,
    });
  }, [toggleBurn, doNotShowAgain]);

  if (connect)
    return <>
      <VSeparator />
        <Opaque>
        <Typography align="center" sx={{ mb: 3 }} variant="h2">1/ Connect wallet</Typography>
        <Wallet account={account} fsm={fsm} />
      </Opaque>
    </>;

  if (optin) {
    return <>
      <VSeparator />
      <Opaque>
        <Typography align="center" sx={{mb: 3}} variant="h2">2/ Opt in to continue</Typography>
        <Button variant="contained" onClick={optinApp}>OPT IN TO CUPSTAKES</Button>
      </Opaque>
    </>;
  }
  // console.log(localAppState.drawAmount, localAppState.getSlots());
  // console.log(fsm.getAvailableActionsObj());
  const slots = localAppState.getSlots().filter(slot => slot > 0);
  const slotTooltip = <>Your available CupStakes draws will wait for you here to withdraw them to your wallet with "Collect". </>;

  const fakeDraw = false;

  return <>
    { modal }
    <DrawWatcher algorand={algorand} localAppState={localAppState} assets={assets} />
    { !ownTeams && !slots.length && !localAppState.drawAmount ? <VSeparator vh={ownTeams ? 0 : 25} /> : null }
    <Opaque>
      { collect ? <VFlex sx={{marginBottom: '1rem'}}>
        <HFlex>
            <Typography align="center" variant="h2">Your CupStake Draws [{slots.length}/3]</Typography>
          <Tooltip extra={<>{slotTooltip}<br /><br />You can draw {3 - slots.length} more CupStakes before you must collect (or burn.)<br /><br />When you are satisfied with your CupStakes slots, collect them to your wallet in order to own them fully.<br/><br/>CupStake NFTs have no Freeze or Clawback. Trading them on secondary markets is encouraged.</>}>{slotTooltip}</Tooltip>
        </HFlex>
        <HFlex>
          { localAppState.getSlots().map((slot, i) => slot > 0 ? 
            <div key={`draws-${i}`} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Team backflip={i+1} {...assets.get(slot)} isNew={localAssets.get(slot)?.amount === 0} amount={localAssets.get(slot)?.amount} burn={toBurn.includes(i+1)} />
              { !localAppState.drawRound ? <HFlex sx={{mt: 2, mb: { xs: 3, md: 0 }}}>
                <Button sx={{ mr: '5px', ml: '32px'  }} size="small" variant={toBurn.includes(i+1) ? 'contained' : 'outlined'} onClick={() => toggleBurnWithExplainer(i+1)} disabled={fsm.isDrawing} startIcon={burnIcon}>BURN {assets.get(slot)?.code}</Button>
                <Tooltip extra={<BurnExplainer />}>{BurnExplainerSummary}</Tooltip>
              </HFlex> : null }
            </div>
          : null).filter(Boolean)}
        </HFlex>
      </VFlex> : null }
      { !slots.length ?
        <Typography sx={{mb: 2}} variant="h2">{localAppState.drawAmount ? 'GOOD LUCK' : "Let's GO"}</Typography>
      : null }
      { toBurn.length ? <BurnBtn toBurn={toBurn} setToBurn={setToBurn} ticket={globalAppState.burnTicketAlgo} /> : <>
        { fakeDraw || localAppState.drawAmount ? <HFlex sx={{mb: 2}}>
          <HourglassIcon sx={{mr: 1}} color="primary" />
          <Typography color="primary">Waiting for draw...</Typography>
        </HFlex> : null }
        { freedraw ? <>
          <div>You have {localAssets.get('free')?.amount} Free Draw ticket{localAssets.get('free')?.amount > 1 ? 's' : ''}!</div>
          <FreeDrawBtn />
        </> : null }
        { !fakeDraw && draw1 ? <DrawBtn disabled={fsm.isDrawing} ticket={globalAppState.ticketAlgo} /> : null }
        { !fakeDraw && draw3 ? <Draw3Btn disabled={fsm.isDrawing} ticket={globalAppState.ticketAlgo * 3} /> : null }
      </> }
      { collect ? <CollectBtn modal={modal} disabled={!!toBurn.length} slots={slots} setModal={setModal} doNotShowAgain={doNotShowAgain} /> : null }
    </Opaque>
    { fakeDraw || (localAppState.drawAmount && !ownTeams)? <VSeparator vh={10} /> : null }
  </>
}

export default observer(Play);
