import Zoom from '../components/Zoom.jsx';
import teams from '../teams.json';

// const flags = teams.map(team => team.flag)
// const cupstakes = 'CUPSTAKES'.split('');
// flags.reverse()
// cupstakes.reverse()
// const items = interleave(flags, cupstakes);
// items.reverse();
// cupstakes.reverse();
// flags.reverse();

const flags = teams.map(team => team.flag)
const cupstakes = 'CUPSTAKES'.split('');
const items = [...flags.slice(0, 16), ...cupstakes, ...flags.slice(16)];

function interleave(...arrs) {
  const out = [];
  const ls = arrs.map(arr => arr.length);
  const maxL = Math.max(...ls);
  for(let i=0; i<maxL; i++) {
    for(const arr of arrs) {
      if (i < arr.length) {
        out.push(arr[i]);
      }
    }
  }
  return out;
}

function Final() {
  return <div>
    <div class="finalsmall">{flags.slice(0, 8).join(' ')}<br/>{flags.slice(8, 16).join(' ')}</div>
    <div style={{marginTop: '20px'}}>CUPSTAKES</div><div>2022</div>
    <div class="finalsmall">{flags.slice(16, 24).join(' ')}<br/>{flags.slice(24).join(' ')}</div>
  </div>;
}

export default function FlagZoom() {
  return <div style={{width: '100%', display: 'flex', flexDirection: 'column', background: 'red'}}>
    <div style={{flex: 1}}>
      <Zoom items={items} final={<Final />} />
    </div>
  </div>;
}
