import html from './FAQ.html';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Left, Opaque, VSeparator } from '../components/Layout.jsx';

const h3Regex = /<h3 id="([^"]+)">([^<]+)<\/h3>/gi;
const toc = [];
let match;
const indexes = [];

while(match = h3Regex.exec(html)) {
  const [id, question] = match.slice(1);
  toc.push([id, question]);
  indexes.push(match.index);
}

const parts = indexes.map((start, i, all) => {
  const end = all[i+1] ?? 0
  return html.slice(start, end-1)
})

export default function () {
  return <div className="faq">
    <VSeparator />
    <Opaque h2>
      <Typography color="faded" variant="h2">Frequently Asked Questions</Typography>
    </Opaque>

    <VSeparator />

    <Opaque>
      <Left>
        {toc.map(([id, question], i) => <Link key={`toc-${i}`} href={`#${id}`}>{question}</Link>)}
      </Left>
    </Opaque>

    {parts.map((part, i) => <><VSeparator />
      <Opaque>
        <Left sx={{width: '100%'}} dangerouslySetInnerHTML={{__html: part}} />
      </Opaque>
      </>)}

    <VSeparator />
  </div>;
}
