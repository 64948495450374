import ARG from './ARG.png';
import AUS from './AUS.png';
import BEL from './BEL.png';
import BRA from './BRA.png';
import CAN from './CAN.png';
import CHE from './CHE.png';
import CMR from './CMR.png';
import CRI from './CRI.png';
import DEU from './DEU.png';
import DNK from './DNK.png';
import ECU from './ECU.png';
import ENG from './ENG.png';
import ESP from './ESP.png';
import FRA from './FRA.png';
import GHA from './GHA.png';
import HRV from './HRV.png';
import IRN from './IRN.png';
import JPN from './JPN.png';
import KOR from './KOR.png';
import MAR from './MAR.png';
import MEX from './MEX.png';
import NLD from './NLD.png';
import POL from './POL.png';
import PRT from './PRT.png';
import QAT from './QAT.png';
import SAU from './SAU.png';
import SEN from './SEN.png';
import SRB from './SRB.png';
import TUN from './TUN.png';
import URY from './URY.png';
import USA from './USA.png';
import WLS from './WLS.png';
import back from './backface.png';

export default { ARG, AUS, BEL, BRA, CAN, CHE, CMR, CRI, DEU, DNK, ECU, ENG, ESP, FRA, GHA, HRV, IRN, JPN, KOR, MAR, MEX, NLD, POL, PRT, QAT, SAU, SEN, SRB, TUN, URY, USA, WLS, back, };
