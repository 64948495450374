import { makeAutoObservable } from "mobx"

class GlobalAppState {
  kill = null;
  ticket = null;
  burnTicket = null;
  maxOdds = null;
  oracleAppId = null;
  freeDrawNft = null;
  maxRandomnessRange = null;
  rewardsPoolAmount = null;

  constructor(newObj) {
    makeAutoObservable(this);
  }

  get ticketAlgo() {
    return this.ticket / 1_000_000;
  }

  get burnTicketAlgo() {
    return this.burnTicket / 1_000_000;
  }

  get rewardsPoolAmountAlgo() {
    return this.rewardsPoolAmount / 1_000_000;
  }

  setState(newState) {
    for(const key of Object.keys(newState)) {
      if (!this.hasOwnProperty(key)) {
        console.warn('GlobalAppState replaceState tried to set unknown key '+key);
        continue;
      }
      this[key] = newState[key];
    }
  }

  replaceState(newState) {
    this.resetState();
    this.setState(newState);
  }

  resetState() {
    for(const key of Object.keys(this)) {
      this[key] = null;
    }
  }
}

export default GlobalAppState;
