import { createTheme } from '@mui/material/styles';
import * as colors from '@mui/material/colors';

let theme = createTheme();

theme = createTheme(theme, {
  typography: {
    allVariants: {
    },
    h1: {
      [theme.breakpoints.down("sm")]: {
        fontSize: '2.5rem',
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: '6.5rem',
      },
      letterSpacing: '0rem',
      textTransform: 'none',
      fontFamily: 'Maldini',
      color: 'var(--primary)',
    },
    h2: {
      [theme.breakpoints.down("sm")]: {
        fontSize: '1.65rem',
      },
      letterSpacing: '0.02rem',
      fontFamily: 'Maldini',
      color: 'var(--primary)',
    },
    h3: {
      [theme.breakpoints.down("sm")]: {
        fontSize: '2rem',
      },
      fontSize: '2.5rem',
      letterSpacing: '0.02rem',
      fontFamily: 'Maldini',
      color: 'var(--primary)',
    },
    p: {
      fontSize: '1.5rem',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          textTransform: 'none',
          borderRadius: 0,
        },
        outlined: {
          background: 'rgba(255, 255, 255, 0.5)',
          "&:hover": {
            background: 'rgba(255, 255, 255, 0.9)',
          }
        }
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#f101a2'
    },
    secondary: {
      main: colors.lightGreen[300],
    },
    faded: {
      main: '#aaaaaa',
    }
  },
});


export default theme;
