import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Team from './Team.jsx';
import { HFlex } from './Layout.jsx';

function NFTs({ localAssets, assets }) {
  const data = localAssets.sortById()
    .filter(([key, value]) => key !== 'free' && value.amount > 0)
    .map(([id, {amount}]) => {
      const assetData = assets.get(id);
      return { id, amount, assetData };
    }).sort((a, b) => {
      const oa = a.assetData.odds;
      const ob = b.assetData.odds;
      if (oa === ob) {
        return a.assetData.rank < b.assetData.rank ? -1 : 1;
      }
      return oa < ob ? -1 : 1
    });

  return <HFlex style={{marginBottom: '318px'}}>
    { data.map(({id, amount, assetData}) => {
      return <Team short={true} {...assetData} key={`your-teams-${id}`} amount={amount} />;
    }) }
  </HFlex>;
}

export default observer(NFTs);
