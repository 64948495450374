import algosdk from 'algosdk';
import _algodClient from './algo/algod-client.js';
import indexer from './algo/indexer.js';
import { fsm, account, localAppState, globalAppState, localAssets, assets } from './state/';
import { contractMethodNames, contractMethodReverse, ALGO } from './constants.js';
import Button from '@mui/material/Button';
import constants from './constants.json';
import { sleep, convertObjectSnakeCaseToCamelCase } from './utils.js';
import CopyButton from './components/CopyButton.jsx';
import teamNFTMap from './team_nft_ids.json';
import teamData from './teams.json';
import { signTransactionWithWallet, signTransactionsWithWallet } from './algo/wallets.js';
import notifications from './notifications.js';
import { convertStringKey, convertIntKey, parseIntResult, waitForConfirmation } from './algo/utils.js';
import Countdown from './components/Countdown.jsx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { HFlex, VFlex } from './components/Layout.jsx';

const { encodeObj } = algosdk;

export const algodClient = _algodClient;
window.ind = indexer;
window.constants = convertObjectSnakeCaseToCamelCase(constants);

window.fil = async function getOwnTransactions(nt) {
  const data = await indexer.searchForTransactions()
    .applicationID(appId)
    .address(account.address)
    .limit(20)
    .do();
  for(const row of data.transactions) {
    if (row['application-transaction'] && row['application-transaction']['application-args']) {
      const methodNameEncoded = row['application-transaction']['application-args'];
      const methodName = contractMethodReverse[methodNameEncoded];
      console.log(row['confirmed-round'], row.sender, methodName, row.id);
    }
  }
}

window.xil = async function getExecTransactions() {
  const data = await indexer.searchForTransactions()
    .applicationID(appId)
    .address(constants.backendAddress)
    .do();
  for(const row of data.transactions) {
    if (row['application-transaction'] && row['application-transaction']['application-args']) {
      const methodNameEncoded = row['application-transaction']['application-args'];
      console.log(contractMethodNames);
      const methodName = contractMethodReverse[methodNameEncoded];
      console.log(row['confirmed-round'], row.sender, methodName, row.id);
    }
  }
}

let navigate;

const { appId, storageAppId, applicationAddress, rewardsAddress, teamNftIssuance, winnerRewardsRatio, runnerupRewardsRatio } = convertObjectSnakeCaseToCamelCase(constants);

const teamNFTIds = Object.keys(teamNFTMap).map(n => Number(n));

async function getGlobalState(appId, processor=convertStringKey) {
  // console.log('getting global state', appId);
  const appInfo = await algodClient.getApplicationByID(appId).do();
  return appInfo.params['global-state'].reduce((out, {key, value: { uint }}) => {
    const newKey = processor(key);
    out[newKey] = uint;
    // console.log(key, newKey, uint);
    return out;
  }, {})
}

export const refreshStorageAppGlobalState = async () => {
  const state = await getGlobalState(storageAppId, convertIntKey);
  const nftOdds = {};
  const numKeys = Object.keys(state).filter(n => !isNaN(parseInt(n, 10)));
  const keys = Object.values(state).filter((_, i) => i % 2 === 0);
  const odds = Object.values(state).filter((_, i) => i % 2 === 1);
  const maxOdds = Math.max(...odds);
  for(let i = 0; i < keys.length; i++) {
    const prevOdds = i > 0 ? odds[i-1] : 0;
    const nftId = keys[i];
    const cumOdds = odds[i];
    if (odds[i] !== 0)
      nftOdds[nftId] = 100 * (cumOdds - prevOdds) / maxOdds;
  }
  for(const id of teamNFTIds) {
    const fullName = teamNFTMap[id];
    const name = fullName.replace(' 2022 CupStake', '');
    const { code, flag, rank, rankLabel } = lookupNFTData(name);
    const { available, odds } = nftOdds[id] ? { available: true, odds: nftOdds[id] } : { available: false, odds: Infinity };
    // console.log(name, code, odds);
    assets.replaceState(id, { available, odds, id, name, code, flag, rank, rankLabel, });
  }
  // console.log("assets done");
}

function lookupNFTData(lookupName) {
  const { flag, countryCode, rank, rankLabel } = teamData.find(({name}) => lookupName.startsWith(name));
  return { flag, code: countryCode, rank, rankLabel };
}

export const refreshRewardsState = async () => {
  const { amount: rewardsPoolAmount } = await getAccountInfoAfter(rewardsAddress, 5);
  globalAppState.setState({rewardsPoolAmount});
  console.log('refreshed rewards state;, rewardsPoolAmount', rewardsPoolAmount);
}

export const refreshNFTIssuanceState = async () => {
  const appAddressStatus = await algodClient.accountInformation(applicationAddress).do();
  const maxIssued = teamNftIssuance;
  const nftBalances = appAddressStatus;
  for(const teamNFTId of teamNFTIds) {
    const asset = appAddressStatus.assets.find(a => a['asset-id'] === teamNFTId);
    if (!asset) {
      console.warn('Team NFT Not found in app address asset balances:', teamNFTId);
      continue;
    }
    const { amount } = asset;
    const assetObj = assets.get(teamNFTId);
    if (assetObj?.issued === amount) {
      // console.log(assetObj.name, 'issuance already up to date');
      continue;
    }
    const issued =  maxIssued - amount;
    if (issued > 0) {
      const update = {
        ...assetObj,
        issued,
        wv: globalAppState.rewardsPoolAmount * winnerRewardsRatio / issued / 1_000_000,
        ruv: globalAppState.rewardsPoolAmount * runnerupRewardsRatio / issued / 1_000_000,
      };
      assets.replaceState(teamNFTId, update);
    }
  }

}

export const refreshAppGlobalState = async () => {
  const state = await getGlobalState(appId)
  globalAppState.setState(state);
}

export const getAppLocalState = async (accountInfo) => {
  accountInfo = accountInfo ?? await algodClient.accountInformation(account.address).do();
  const localStateData = accountInfo['apps-local-state'].find(({id}) => id === appId);
  const appState = {};
  if (!localStateData) {
    console.warn('No local state data');
    return {};
  }
  for(const { key, value: { uint } } of localStateData["key-value"]) {
    const keyName = convertStringKey(key);
    appState[keyName] = uint ?? 0;
  }
  return { round: accountInfo.round, appState };
}

export const refreshAppLocalState = async (accountInfo, targetRound) => {
  if (targetRound) {
    await getStatusAfter(targetRound);
  }
  const { round, appState } = await getAppLocalState(accountInfo);
  // console.log('setting local app state', appState);
  localAppState.replaceState(appState);
  return { round, localAppState };
}

export const refreshNFTBalances = async (accountInfo) => {
  accountInfo = accountInfo ?? await algodClient.accountInformation(account.address).do();
  const { freeDrawNft } = globalAppState;
  const nftIds = [...teamNFTIds, freeDrawNft];
  const assets = accountInfo['assets'].filter(asset => nftIds.includes(asset['asset-id']))
  const localAssetData = {};
  for(const asset of assets) {
    const { "asset-id": id, amount } = asset;
    if (id === freeDrawNft) {
      localAssetData.free = {
        opted_in: true,
        amount,
      }
    } else {
      localAssetData[id] = {
        opted_in: true,
        amount,
      }
    }
  }
  if (!localAssetData.free) {
      localAssetData.free = {
        opted_in: false,
        amount: 0,
      }
  }
  for(const teamNFTId of teamNFTIds) {
    if (!localAssetData[teamNFTId]) {
      localAssetData[teamNFTId] = {
        opted_in: false,
        amount: 0,
      }
    }
  }
  console.log('setting local nfts', localAssetData);
  runInAction(() => {
    for(const [id, data] of Object.entries(localAssetData)) {
      localAssets.replaceState(id, data);
    }
  })
  const f = localAssets.free
  // console.log(f.opted_in);
  // console.log(f.amount);
}

export const refreshBalances = async () => {
  try {
    console.log('refreshing balances', account?.address);
    let accountInfo;
    if (account.address) {
      accountInfo = await algodClient.accountInformation(account.address).do()
      const isOptedIn = !!accountInfo['apps-local-state'].find(i => i.id === appId)
      const balance = accountInfo.amount/1_000_000
      const available = (Math.max(0, accountInfo.amount - accountInfo['min-balance']))/1_000_000
      account.setBalance(balance.toFixed(2));
      account.setAvailableBalance(available.toFixed(2));
      account.setAvailableBalanceNum(available * 1_000_000);
      account.setOptedIn(isOptedIn);
      // TODO await promise all
      if (isOptedIn) {
        refreshAppLocalState(accountInfo);
      }
    }
    await refreshGlobalState();
    if (accountInfo) {
      refreshNFTBalances(accountInfo);
    }
    return accountInfo;
  } catch(e) {
    console.log(e);
    notifications.warning(<div>Failed to update balance:<br />${e.message}</div>);
  }
}

export const refreshGlobalState = async() => {
  await Promise.all([
    refreshAppGlobalState(),
    refreshStorageAppGlobalState(),
    refreshRewardsState(),
  ]);
  await refreshNFTIssuanceState();
}

export const clearoutApp = async () => {
  const suggestedParams = await algodClient.getTransactionParams().do();
  suggestedParams.flatFee = true;
  const sender = account.address;
  const txns = await makeCollectTxns();
  txns.splice(txns.length-1); // get rid of collect call - closeout does this
  const availableNFTs = [...new Set(localAppState.getSlots().filter(Boolean))];
  suggestedParams.fee = 1000 + (1000 * availableNFTs.length);
  txns.push(algosdk.makeApplicationClearOutTxnFromObject({
    suggestedParams,
    from: sender,
    appIndex: appId,
    foreignAssets: availableNFTs,
  }));
  await executeTxns(txns);
  refreshBalances();
}

export const optoutApp = async () => {
  const suggestedParams = await algodClient.getTransactionParams().do();
  suggestedParams.flatFee = true;
  const sender = account.address;
  const txns = await makeCollectTxns();
  txns.splice(txns.length-1); // get rid of collect call - closeout does this
  const availableNFTs = [...new Set(localAppState.getSlots().filter(Boolean))];
  suggestedParams.fee = 2000 + (1000 * availableNFTs.length);
  txns.push(algosdk.makeApplicationCloseOutTxnFromObject({
    suggestedParams,
    from: sender,
    appIndex: appId,
    foreignAssets: availableNFTs,
  }));
  await executeTxns(txns);
  refreshBalances();
}

export const makeAssetTransferTxn = async (id, to, amount) => {
  const suggestedParams = await algodClient.getTransactionParams().do();
  const from = account.address;
  return algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject({
    from,
    to,
    amount,
    assetIndex: id,
    suggestedParams,
  });
}

export const makeAssetOptinTxn = (id) => {
  return makeAssetTransferTxn(id, account.address, 0);
}

export const optinApp = async () => {
  const suggestedParams = await algodClient.getTransactionParams().do();
  const sender = account.address;
  const txns = [algosdk.makeApplicationOptInTxnFromObject({
    suggestedParams,
    from: sender,
    appIndex: appId,
  })];
  const l = localAssets.get('free');
  if (!l.opted_in) {
    const id = globalAppState.freeDrawNft;
    txns.push(await makeAssetOptinTxn(id));
  }
  await executeTxns(txns);
  refreshBalances();
}

async function executeTxn(txn) {
  let signedTxn = await signTransactionWithWallet(account, txn);
  if (!signedTxn) return;
  try {
    notifications.info('Sending transaction', false, 'sendTx');
    const response = await algodClient.sendRawTransaction(signedTxn).do();
    notifications.closeSnackbar('sendTx');
    const { txId } = response;
    notifications.info('Transaction sent. Waiting for confirmation', 0, 'waitConfirm');
    const confirmed = await waitForConfirmation(txId);
    notifications.closeSnackbar('waitConfirm');
    handleTxnNotify(txId);
    return confirmed;
  } catch(e) {
    handleTxnError(e);
  } finally {
    notifications.closeSnackbar('sendTx');
    notifications.closeSnackbar('waitConfirm');
  }
}

function shorten(str, len = 8) {
  const end = len - 1
  if (str?.length > 12) {
    return str.slice(0, end)+".."+str.slice(-len);
  }
}

window.txnn = handleTxnNotify

function handleTxnNotify(txId) {
  notifications.info(<VFlex sx={{alignItems: 'flex-start'}}>
    <HFlex sx={{mb: 0.5}}><span>Transaction confirmed {shorten(txId, 6)}</span></HFlex>
    <HFlex>
      
      <Button variant="outlined" size="small" sx={{mr: 1}} onClick={() => window.open(`https://algoexplorer.io/tx/${txId}`)}>VIEW</Button>
      <CopyButton variant="outlined" size="small" sx={{mr: 0}} value={txId}></CopyButton>
    </HFlex>
  </VFlex>, 6000, 'transaction-confirmed');
}

function handleTxnError(e) {
  console.error(e);
  const contractError = parseContractError(e.message);
  const message = contractError ? <div style={{display: 'flex', flexDirection: 'column'}}>
    <span>Smart Contract Error: {contractError}</span>
    <div><Button sx={{mr: 1}} size="small" variant="outlined" color="error" onClick={() => alert(e.message)}>VIEW FULL</Button>
      <CopyButton value={e.message} size="small" color="error" variant="outlined" /></div>
  </div> : e.message;
  notifications.error(message, 0);
  throw e;
}

async function executeTxns(txns, txIdxToReturn, targetRound) {
  algosdk.assignGroupID(txns);
  let signedTxns = await signTransactionsWithWallet(account, txns);
  if (!signedTxns) return;
  const status = await algodClient.status().do();
  const beforeRound = status['last-round'];
  if (!signedTxns) return;
  if (beforeRound < targetRound) {
    const sec = Math.ceil((targetRound - beforeRound) * 3.7)
    notifications.info(<div>Waiting for round {targetRound}<br/>Should be about {sec} seconds</div>, false, 'waitRound');
    await timeRound(targetRound);
    notifications.closeSnackbar('waitRound');
  }
  try {
    // throw new Error('logic eval error  pushbytes  // "ERR TEST" ');
    if (txIdxToReturn >= txns.length) {
      throw new Error('txIdxToReturn out of bounds');
    }
    notifications.info('Sending transactions', false, 'sendTx');
    console.log('sending', signedTxns);
    const response = await algodClient.sendRawTransaction(signedTxns).do();
    notifications.closeSnackbar('sendTx');
    let txIds = [];
    if (txIdxToReturn === -1) {
      txIds = txns.map(txn => txn.txID());
    } else { 
      const idx = typeof txIdxToReturn === "undefined" ? txns.length - 1 : txIdxToReturn;
      txIds = [txns[idx].txID()];
    }
    notifications.info('Transaction sent. Waiting for confirmation', 0, 'waitConfirm');
    const result = await Promise.all(txIds.map(txId => waitForConfirmation(txId)));
    notifications.closeSnackbar('waitConfirm');
    const txId = txIds[txIds.length-1];
    handleTxnNotify(txId);
    return result;
  } catch(e) {
    handleTxnError(e);
  } finally {
    notifications.closeSnackbar('sendTx');
    notifications.closeSnackbar('waitConfirm');
  }
}

const burnMethods = {
  1: 'burn_draw',
  2: 'burn_draw2',
  3: 'burn_draw3',
};

function formatAlgoPrice(num) {
  return `${ALGO}${(num / 1_000_000).toFixed(2)}`;
}

async function ensureHaveAmount(amount) {
  const ab = account.availableBalanceNum;
  const leftOverNum = account.availableBalanceNum - amount;
  const leftOver = formatAlgoPrice(leftOverNum);
  const availableBalance = formatAlgoPrice(account.availableBalanceNum);
  const prettyAmount = formatAlgoPrice(amount);

  if (leftOverNum <= 0) {
    notifications.error(<div><strong>You do not have enough available balance to do this</strong><br/>Available Balance: {availableBalance}<br />ALGO required: {prettyAmount}</div>);
    throw new Error('Not enough balance');
  } else if (leftOverNum < 0.1 * 1_000_000) {
    notifications.warning(<div><strong>Low Balance Warning</strong><br />If you perform this action, you will only have {leftOver} available balance remaining</div>);
    await sleep(2500);
  }
};

export const sendBurnTxns = async (...slots) => {
  const drawAmount = slots.length;
  const methodName = burnMethods[drawAmount];
  const amount = globalAppState.burnTicket * drawAmount;
  const suggestedParams = await algodClient.getTransactionParams().do();
  const oracleAppId = globalAppState.oracleAppId;
  const appArgs = [ contractMethodNames[methodName], ...slots.map( s => encodeObj(s) ) ];
  const txns = [
    algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams,
      from: account.address,
      to: rewardsAddress,
      amount,
      // note: encodeObj(`CupStakes.world ${drawAmount}x discounted ticket payment`),
    }),
    algosdk.makeApplicationNoOpTxnFromObject({
      from: account.address,
      appIndex: appId,
      foreignApps: [oracleAppId, storageAppId],
      accounts: [account.address],
      appArgs,
      suggestedParams,
      // note: encodeObj(`CupStakes.world execute ${methodName}`),
    })
  ];
  fsm.setIsDrawing(true);
  try {
    await ensureHaveAmount(amount);
    const response = await executeTxns(txns, 1);
    if (!response) return;
    const waitForRound = parseIntResult(response[0].logs);
    const { confirmedRound } = response[0]['confirmed-round'];
    await refreshBalances();
    // waitForDrawExec();
  } catch(e) {
    console.log(txns);
  } finally {
    fsm.setIsDrawing(false);
  }

}

window.x = sendBurnTxns;

export const sendExecTxns = async () => {
  const { round: beforeRound } = await refreshBalances();
  if (!localAppState.drawRound) {
    return notifications.error('Cannot execute draw right now. Try reloading the page.');
  }
  const targetRound = localAppState.drawRound + 2;

  const { drawAmount, drawRound } = localAppState;
  const suggestedParams = await algodClient.getTransactionParams().do();
  suggestedParams.flatFee = true;
  suggestedParams.fee = (2000 * drawAmount);

  // TODO check can draw
  const ticket = globalAppState.ticket;
  const oracleAppId = globalAppState.oracleAppId;

  const txn = algosdk.makeApplicationNoOpTxnFromObject({
    from: account.address,
    appIndex: appId,
    foreignApps: [oracleAppId, storageAppId],
    accounts: [account.address],
    appArgs: [
      contractMethodNames.exec_draw,
    ],
    suggestedParams,
    // note: encodeObj(`CupStakes.world execute exec_draw`),
  });

  try {
    const response = await executeTxn(txn, 0, targetRound);
    // waitForDrawExec(() => notifications.info('Draw success! Reload to see your draws'));
    if (!response)
      return;
    const waitForRound = parseIntResult(response[0].logs);
    const { confirmedRound } = response[0]['confirmed-round'];
    await refreshBalances();
    console.log('refreshed balances');
  } catch(e) {
    console.log(txn);
  }
}

export const sendFreeDrawTxns = async () => {
  const { round: beforeRound } = await refreshBalances();
  timeRound(beforeRound+1);
  timeRound(beforeRound+2);
  timeRound(beforeRound+3);
  const suggestedParams = await algodClient.getTransactionParams().do();
  // TODO check can draw
  const ticket = globalAppState.ticket;
  const oracleAppId = globalAppState.oracleAppId;

  // fsdFAw==
  // remove backend pay
  // send asset tx
  const txns = [];
  txns.push(await makeAssetTransferTxn(globalAppState.freeDrawNft, applicationAddress, 1));

  const args = {
    from: account.address,
    appIndex: appId,
    foreignApps: [storageAppId],
    appArgs: [
      contractMethodNames.free_draw,
    ],
    suggestedParams,
    // note: encodeObj(`CupStakes.world execute free_draw`),
  };
  txns.push(algosdk.makeApplicationNoOpTxnFromObject(args));

  try {
    const response = await executeTxns(txns, 1);
    if (!response) return;
    const waitForRound = parseIntResult(response[0].logs);
    const { confirmedRound } = response[0]['confirmed-round'];
    // timeRound(confirmedRound+1);
    // timeRound(confirmedRound+2);
    await refreshBalances();
    const drawRound = localAppState.drawRound;
  } catch(e) {
    console.error(e);
  }
}

const contractErrorRegex = /logic eval error.*assert.*byte.*\/\/ "([^"]+)"/;
function parseContractError(message) {
  const match = contractErrorRegex.exec(message);
  return match && match[1];
}

export const sendDraw1Txns = async () => {
  const { round: beforeRound } = await refreshBalances();
  timeRound(beforeRound+1);
  timeRound(beforeRound+2);
  timeRound(beforeRound+3);
  const suggestedParams = await algodClient.getTransactionParams().do();
  // TODO check can draw
  const ticket = globalAppState.ticket;
  const oracleAppId = globalAppState.oracleAppId;

  const txns = [
    algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams,
      from: account.address,
      to: rewardsAddress,
      amount: ticket,
      // note: encodeObj(`CupStakes.world 1x ticket payment`),
    }),
    algosdk.makeApplicationNoOpTxnFromObject({
      from: account.address,
      appIndex: appId,
      foreignApps: [storageAppId],
      appArgs: [
        contractMethodNames.draw,
      ],
      suggestedParams,
      // note: encodeObj(`CupStakes.world execute draw`),
    })
  ];

  fsm.setIsDrawing(true);
  try {
    await ensureHaveAmount(ticket);
    const response = await executeTxns(txns, 1);
    if (!response) return;
    const waitForRound = parseIntResult(response[0].logs);
    const { confirmedRound } = response[0]['confirmed-round'];
    await refreshBalances();
    // waitForDrawExec();
  } catch(e) {
    console.log(txns);
  } finally {
    fsm.setIsDrawing(false);
  }
}

const defaultNext = function () {
  window.location.href='/draw';
}

// async function waitForDrawExec(next=defaultNext) {
//   const { drawRound } = localAppState;
//   const resultRound = drawRound + 3;
//   const expectedTime = projectTimeForRound(resultRound);
//   const tDiff = expectedTime - Date.now();
//   const tMinus = tDiff - 3000
//   console.log('waitForDrawExec', tMinus);
//   if (tMinus > 0) {
//     await notifications.info(<>Waiting for draw result, about: <Countdown from={tDiff/100} /> seconds</>, tMinus, 'waiting-draw');
//     console.log('timeout next', tMinus);
//     setTimeout(next, tMinus);
//   } else {
//     console.log("instant next");
//     next()
//   }
// }

export const sendDraw3Txns = async () => {
  const suggestedParams = await algodClient.getTransactionParams().do();
  // TODO check can draw
  const ticket = 3 * globalAppState.ticket;
  const oracleAppId = globalAppState.oracleAppId;
  const txns = [
    algosdk.makePaymentTxnWithSuggestedParamsFromObject({
      suggestedParams,
      from: account.address,
      to: rewardsAddress,
      amount: ticket,
      // note: encodeObj(`CupStakes.world 3x ticket payment`),
    }),
    algosdk.makeApplicationNoOpTxnFromObject({
      from: account.address,
      appIndex: appId,
      foreignApps: [storageAppId],
      appArgs: [
        contractMethodNames.draw3
      ],
      suggestedParams,
      // note: encodeObj(`CupStakes.world execute draw3`),
    }),
  ];
  fsm.setIsDrawing(true);
  try {
    await ensureHaveAmount(ticket);
    const response = await executeTxns(txns, 1);
    if (!response) return;
    const waitForRound = parseIntResult(response[0].logs);
    const { confirmedRound } = response[0]['confirmed-round'];
    await refreshBalances();
    const drawRound = localAppState.drawRound;
    // waitForDrawExec();
  } catch(e) {
    console.log(txns);
  } finally {
    fsm.setIsDrawing(false);
  }
}

function getStatus() {
  return algodClient.status().do();
}

function getStatusAfter(round) {
  return algodClient.statusAfterBlock(round).do();
}

function getAccountInfo(address) {
  return algodClient.accountInformation(address).do();
}

async function getAccountInfoAfter(address, targetRound) {
  const acctInfoNow = await getAccountInfo(address);
  const { round } = acctInfoNow;
  if (round >= targetRound) {
    return acctInfoNow;
  }
  await getStatusAfter(targetRound);
  return getAccountInfo(address);
}

const times = [];
async function timeRound(round) {
  console.log(new Date(), 'waiting after block', round);
  const sAB = await algodClient.statusAfterBlock(round-1).do()
  const lastTime = times.length > 0 ? times[times.length-1][1] : NaN;
  const now = Date.now();
  times.push([round, now]);
  console.log(round, sAB['last-round'], new Date(), now, now - lastTime);
}

async function makeCollectTxns() {
  const availableNFTs = localAppState.getSlots().filter(Boolean);
  if (availableNFTs.length) {
    const optinIds = new Set();
    const txns = [];
    for(const id of availableNFTs) {
      if (!localAssets.get(id).opted_in) {
        optinIds.add(id);
      }
    }
    if (optinIds.size) {
      for(const id of optinIds) {
        txns.push(await makeAssetOptinTxn(id));
      }
    }
    const suggestedParams = await algodClient.getTransactionParams().do();
    suggestedParams.flatFee = true;
    suggestedParams.fee = availableNFTs.length * 1000 + 1000;
    const args = {
      from: account.address,
      appIndex: appId,
      foreignAssets: availableNFTs,
      appArgs: [
        contractMethodNames.collect,
      ],
      suggestedParams,
      // note: encodeObj(`CupStakes.world execute collect`),
    };
    txns.push(algosdk.makeApplicationNoOpTxnFromObject(args));
    return txns;
  }
  return [];
}

export const sendCollectTxns = async () => {
  try {
    const txns = await makeCollectTxns();
    const response = txns.length > 1 ? await executeTxns(txns, -1) : await executeTxn(txns[0]);
    refreshBalances();
    if (!response) return;
  } catch(e) {
    console.error(e);
  }
}

let _secsPerBlock;
let _lastRound;
let _lastBlockTs;

let benchmarkResolve;
const benchmarkPromise = new Promise(resolve => benchmarkResolve = resolve);

async function benchmarkAlgo() {
  const status = await algodClient.status().do();
  const lastRound = _lastRound = status['last-round'];
  const prevRound = Math.max(1, lastRound - 120);
  const roundDiff = lastRound - prevRound;
  const lastBlock = await algodClient.block(lastRound).do();
  const prevBlock = await algodClient.block(prevRound).do();
  const lastBlockTs = _lastBlockTs = lastBlock.block.ts;
  const prevBlockTs = prevBlock.block.ts;
  const blocksPerSec = roundDiff / (lastBlockTs - prevBlockTs);
  const secsPerBlock = _secsPerBlock = 1 / blocksPerSec;
  for(const step of [1000, 4, 4*60, 4*60*60]) {
    const t = projectTimeForRound(lastRound + step, secsPerBlock, lastRound, lastBlockTs);
    console.log(step, new Date(t*1000), 'rounds will take', (t - Date.now())/1000);
  }
  benchmarkResolve();
}

export async function projectTimeForRoundAsync(targetRound) {
  await benchmarkPromise;
  return projectTimeForRound(targetRound);
}

export function projectTimeForRound(targetRound, secsPerBlock = _secsPerBlock, lastRound = _lastRound, lastRoundTs = _lastBlockTs) {
  const elapsedSinceLastRound = Date.now() - (lastRoundTs * 1000);
  const roundDiff = targetRound - lastRound;
  const timeForRounds = secsPerBlock * roundDiff * 1000;
  const expectedTime = Math.floor(Date.now() + timeForRounds - elapsedSinceLastRound);
  return expectedTime;
}
window.pt = projectTimeForRound;

async function main() {
  try {
    await refreshGlobalState();
    await benchmarkAlgo();
  } catch(e) {
    notifications.error(e.message);
  }
}

setTimeout(() => {
  main();
}, 1000);

export const setNavigate = (_navigate) => navigate = _navigate;

window.n = notifications;
