import { useEffect } from 'react';
import images from './cupstakes-images.js';

function preloadImage (src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      resolve(img)
    }
    img.onerror = img.onabort = function() {
      reject(src)
    }
    img.src = src
  })
}

export default function Preload() {
  useEffect(() => {
    setTimeout(() => {
      Object.values(images).map((src, i) => {
        preloadImage(src);
      });
    }, 1000);
  }, []);
  return <></>;
}
