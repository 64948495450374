import { useState, useEffect } from 'react';
import { algodClient } from '../algo.js';

const useAlgodRound = () => {
  const [round, setRound] = useState()
  const [fail, setFail] = useState(0);

  useEffect(() => {
    (async function run() {
      try {
        if (round) {
          const status = await algodClient.statusAfterBlock(round).do();
          setRound(status['last-round']);
        } else {
          const status = await algodClient.status().do();
          setRound(status['last-round']);
        }
        setFail(0);
      } catch(e) {
        const timeout = Math.min(16_000, Math.pow(fail, 2) * 1000);
        console.log('caught', e.message, 'retry', timeout);
        setTimeout(() => setFail(fail + 1), timeout);
      }
    })()
  }, [round, fail]);
  console.log(round, fail);

  return [round, fail];
};

export default useAlgodRound;
