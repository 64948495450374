import { useState, useCallback, useMemo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export default function({children, extra, color, moreInfo=" Click for more info.", ...props}) {
  const [anchorEl, setAnchorEl] = useState();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tooltipText = useMemo(() => extra && moreInfo ? <>{children}{moreInfo}</> : children, [children, extra, moreInfo]);


  return <><Tooltip title={tooltipText}>
    <IconButton color="faded" onClick={handleClick} size="small" {...props}>
      <InfoOutlinedIcon fontSize="inherit" />
    </IconButton>
  </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      > <>
        <Typography sx={{ p: 2, pb:1 }}>{extra ?? children}</Typography>
      </>
      </Popover></>

}
