import notifications from '../notifications.js';

export async function signTransactionsWithWallet (account, txns) {
  if (!Array.isArray(txns)) {
    txns = [txns];
  }
  if (account.wallet === "pera") {
    const txnGroup = txns.map(txn => ({txn}));
      notifications.info('Please sign the transaction on Pera', false, 'signTx');
      try {
        return (await account.walletObject.signTransaction([txnGroup]));
      } catch (e) {
        notifications.error(e.message);
        return;
      } finally {
        notifications.closeSnackbar('signTx');
      }
  } else if (account.wallet === "myalgo") {
      const myAlgoConnect = account.walletObject;
      notifications.info('Please sign the transaction on MyAlgo', false, 'signTx');
      try {
        return (await myAlgoConnect.signTransaction(txns.map(txn => txn.toByte()))).map(txn => txn.blob);
      } catch(e) {
        notifications.error(e.message);
        return;
      } finally {
        notifications.closeSnackbar('signTx');
      }
  } else if (account.wallet === "algosigner") {
      const AlgoSigner = account.walletObject;
      try {
        const bTxns = txns.map(txn => ({ txn: AlgoSigner.encoding.msgpackToBase64(txn.toByte()) }))
        notifications.info('Please sign the transaction on AlgoSigner', false, 'signTx');
        const signed = (await AlgoSigner.signTxn(bTxns));
        if (signed.length && signed[0].blob) {
          return signed.map(txn => AlgoSigner.encoding.base64ToMsgpack(txn.blob));
        }
        throw new Error('Could not receive signed txn from AlgoSigner');
      } catch(e) {
        notifications.error(e.message);
        return;
      } finally {
        notifications.closeSnackbar('signTx');
      }
  } else {
    notifications.error(`No wallet connected`);
    return;
  }
}

export const signTransactionWithWallet = signTransactionsWithWallet;
