import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import Team from './Team.jsx';
import { sendDraw1Txns, sendCollectTxns, sendExecTxns } from '../algo';

function AllNFTs({ assets }) {
  const data = assets.entries()
    .sort(([_, {rank: r1, odds: o1, ...p}], [__, {rank: r2, odds: o2}]) => {
      if (o1 === o2) {
        return r1 < r2 ? -1 : 1;
      }
      return o1 < o2 ? -1 : 1;
    });

  return <>
    { data.map(([id, assetData]) => {
      return <Team key={`all-teams-${id}`} showIssued={true} {...assetData} />
    }) }
    </>;
}

export default observer(AllNFTs);
