import { useMemo, useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useNavigate, useLocation } from 'react-router-dom';
import { VSeparator, Opaque, HFlex, VFlex } from '../components/Layout';
import { observer } from 'mobx-react-lite';
import { sendCollectTxns, sendDraw1Txns } from '../algo.js';
import { account, assets, localAssets, localAppState, globalAppState } from '../state/';
import Play from '../components/Play.jsx';
import NFTs from '../components/NFTs.jsx';
import AllNFTTable from '../components/AllNFTTable.jsx';
import AllNFTs from '../components/AllNFTs.jsx';

function Teams({}) {
  const location = useLocation();
  useEffect(() => {
  });
  return <>
    <VSeparator />

    <Opaque>
      <Typography align="center" id="#table" variant="h2">All Teams</Typography>
      <HFlex><Link href="#data">Jump to data table</Link></HFlex>
    </Opaque>

    <VSeparator />

    <Opaque op={0}>
      <Typography align="center" className="glow" sx={{mb: 2}} variant="h2">CUPSTAKE NFTs</Typography>
      <HFlex>
        <AllNFTs assets={assets} localAssets={localAssets} />
      </HFlex>
    </Opaque>

    <VSeparator id="data" />

    <Opaque>
      <Typography align="center" sx={{mb: 2}} variant="h2">CUPSTAKES DATA TABLE</Typography>
      <AllNFTTable assets={assets} localAssets={localAssets} />
    </Opaque>

    <VSeparator />
  </>
}

export default observer(Teams);
